window.Wishlist = {
    Utils: {
        performAjaxRequest: function (action, data, callback) {
            data['action'] = action;

            $.ajax({
                url: '/wishlist.php',
                method: 'POST',
                dataType: 'json',
                data: data,
                success: callback
            });
        }
    },
    Product: {
        /*
         *  Wunschliste
         */
        products : {},

        addToWishList: function(oxartnum) {
            var self = this;
            window.Wishlist.Utils.performAjaxRequest('addProductToWishList', {
                'oxartnum': oxartnum
             }, function (result) {
                if (result.success) {
                    console.log('Produkt ' + oxartnum + ' wurde Ihrer Wunschliste hinzugefügt');

                    $('.delete-from-wishlist[data-oxartnum="' + oxartnum +'"]').show();
                    $('.add-to-wishlist[data-oxartnum="' + oxartnum +'"]').hide();

                } else {
                    console.log('Fehler beim Hinzufügen zur Wunschliste')
                }

                self.updateWishList();
            })
        },
        deleteFromWishList: function(oxartnum) {
            var self = this;
            window.Wishlist.Utils.performAjaxRequest('deleteProductFromWishList', {
                'oxartnum': oxartnum
            }, function (result) {
                if (result.success) {
                    console.log('Produkt ' + oxartnum + ' wurde von Ihrer Wunschliste entfernt');

                    $('.delete-from-wishlist[data-oxartnum="' + oxartnum +'"]').hide();
                    $('.add-to-wishlist[data-oxartnum="' + oxartnum +'"]').show();

                } else {
                    console.log('Fehler beim Löschen von der Wunschliste')
                }

                self.updateWishList();
            });
        },
        getFromWishList: function() {
            return this.products;
        },
        updateWishList : function () {
            var self = this;
            window.Wishlist.Utils.performAjaxRequest('getProductsFromWishList', { }, function (result) {
                self.products = result.products;

                self.updateWishListUI();
            });
        },
        updateWishListUI: function() {
            var html = '';
            var count = 0;

            var $sShopDomain = 'https://shop.koberg-tente.de/';
            var $sThumbnailBaseImagePath = $sShopDomain + 'out/pictures/generated/product/1/185_150_75/';
            var $imagePath = $sShopDomain + $sThumbnailBaseImagePath;

            for(var oxartnum in this.products) {

                var product = this.products[oxartnum];

                html += '<li class="popup__listitem">';
                html += '    <div class="clearfix">';
                html += '    <span class="circle-border">';
                html += '       <img class="popup__item-img" src="'+ $imagePath + product['oxpic1'] +'" alt="' + product['oxtitle'] + '">';
                html += '    </span>';
                html += '    <span class="popup__item-name">' + product['oxtitle'] + '</span>';
                html += '        <span class="popup__item-num">' + product['oxartnum'] + '</span>';
                html += '    </div>';
                html += '</li>';

                count++;
            }

            if (count < 1) {
                html = '<p>Es befinden sich keine Produkte auf Ihrer Wunschliste.</p>';
            }

            // Produkte updaten
            $('.popup--wishlist .popup__list').html(html);

            // Zähler updaten
            $('.popup-trigger .badge').html(count);
            $('.popup--wishlist .popup__number').html(count);
        },

        isOnList : function (oxartnum) {
            for (var i in this.products) {
                if (this.products[i].oxartnum == oxartnum) {
                    return true;
                }
            }

            return false;
        }
    }
};

$(document).ready(function() {

    /*
     * Wunschliste
     */

    //Wunschliste Popup
    $(".popup-trigger").click(function () {
        $(".popup--wishlist").toggle();
    });

    // Produkt zur Wunschliste hinzügen
    $(document).on('click', '.add-to-wishlist', function() {
        var oxartnum = $(this).attr('data-oxartnum');

        window.Wishlist.Product.addToWishList(oxartnum);

        return false;
    });

    // Produkt von der Wunschliste löschen
    $(document).on('click', '.delete-from-wishlist', function() {
        var oxartnum = $(this).attr('data-oxartnum');

        window.Wishlist.Product.deleteFromWishList(oxartnum);

        return false;
    });


    // Wunschlisten-Popup aktualisieren
    window.Wishlist.Product.updateWishList();


    //Menü-Icon animation auslösen
    $('.menu-icon').click(function () {
        $('body').toggleClass('fixed');
        $(this).toggleClass('open');
        $('.nav0-1').fadeToggle('fast');
    });

    // Prüfen ob Untermenüpunkte existieren
    var submenuitem = $('nav ul li');
    submenuitem.find('div').prev('a').append('<span class="dropdown-icon visible-xs"><img alt="Dropdown-Icon" src="img/angle-down.svg"></span>');

    // Submenüebenen (de)aktivieren
    $('nav ul li a').click(function () {
        submenuitem.find('div.active').prev('a').find('.dropdown-icon').removeClass('rotate');
        var sub = $(this).next('div');
        if (sub) {
            $(sub).toggleClass('active');
        }
        submenuitem.find('div.active').prev('a').find('.dropdown-icon').addClass('rotate');
    });


    if ($('.custom-select').length) {
        $('.custom-select').selectric();
    }

    // $('#filterung').click(function(){
    //     $('.filter-color--slider').get(0).slick.setPosition();
    // });


    if ($('.filter-color--slider').length > 0) {
        $('.filter-color--slider').slick({
            infinite: false,
            dots: false,
            arrows: true,
            slidesToShow: 8,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1170,
                    settings: {
                        slidesToShow: 5
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 5
                    }
                },
                {
                    breakpoint: 560,
                    settings: {
                        slidesToShow: 6
                    }
                },
                {
                    breakpoint: 420,
                    settings: {
                        slidesToShow: 5
                    }
                },
                {
                    breakpoint: 380,
                    settings: {
                        slidesToShow: 4
                    }
                }
            ]
        });
    }

    if ($('.model-slider').length) {
        $('.model-slider').slick({
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false,
            dots: true,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }

    if ($('.teaser-start-slider').length) {
        $('.teaser-start-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3100
        })
    }


    // $('body').on('click', '.model-item .overlay', function(){
    //     $(this).parent(".model-item").next(".info-box").fadeIn("fast");
    //     $('body').addClass('fixed');
    // });
    //
    // $('body').on('click', '.close-info-box', function() {
    //     $(this).parents(".info-box").fadeOut("fast");
    //     $('body').removeClass('fixed');
    // });

    $('.dropdown-menu').on('click', function(event){
        event.stopPropagation();
    });

    // $(document).mouseup(function(e)
    // {
    //     var filter = $('.dropdown-menu');
    //     var filterbtn = $('#filterung');
    //
    //     // if the target of the click isn't the container nor a descendant of the container
    //     if (!filter.is(e.target) && filter.has(e.target).length === 0)
    //     {
    //         if (filter.hasClass('is-visible')) {
    //             filter.removeClass('is-visible');
    //             filterbtn.removeClass('opened');
    //         }
    //     }
    //
    // });

    $('#filterung').mouseup(function () {
        if ($('.dropdown-menu').hasClass('is-visible')) {
            $('.dropdown-menu').removeClass('is-visible');
            $(this).removeClass('opened');
            console.log('closed');
        } else {
            $('.dropdown-menu').addClass('is-visible');

            if ($('.filter-color--slider').length > 0) {
                $('.filter-color--slider').get(0).slick.setPosition();
            }

            $(this).addClass('opened');
            console.log('opened');
        }
    });

    $(".model-item").fancybox({
        wrapCSS     : 'fancybox-model',
        type        : 'ajax',
        arrows      : false,
        closeBtn    : false,
        padding     : [40, 80, 40, 80],
        href        : 'ajax.php',

        helpers : {
            title : {
                type : 'inside'
            },
            overlay : {
                css : {
                    'background' : 'transparent'
                }
            }
        },
        scrolling   : 'no',
        fitToView   : true,
        autoSize    : false,
        autoHeight  : true,
        beforeShow: function() {
            this.width = 1090;

            if ($('.buegel-dev').length > 0) {
                var oxartnum = this.element.context.dataset.fancyboxArtnum;
                var blOnList = window.Wishlist.Product.isOnList(oxartnum);

                $('<button ' + (blOnList ? 'style="display:none;"' : '') + ' class="star-info-box pull-right add-to-wishlist" data-oxartnum="' + oxartnum + '"><span><i class="icon-star"></i></span></button>').appendTo('.fancybox-skin');
                $('<button ' + (blOnList ? '' : 'style="display:none;"') + ' class="star-info-box pull-right delete-from-wishlist" data-oxartnum="' + oxartnum + '"><span><i class="icon-star"></i></span></button>').appendTo('.fancybox-skin');
            }
        },
        afterShow: function() {
            $('<button onclick="$.fancybox.close()" class="close-info-box pull-right"><i class="icon-close"></i></button>').appendTo('.fancybox-skin');
            $('<span class="fancy-prev fancybox-arrow" aria-label="Previous"><i class="icon-arrow_left"></i></span><span class="fancy-next fancybox-arrow" aria-label="Next"><i class="icon-arrow_right"></i></span>').appendTo(".fancybox-model");
            $('.fancybox-arrow').show();

            $('.modell-slider-master')
                .on('init.slick', function(event, slick){
                    var oxartnum = $(slick.$slides.get(0)).find('img').data('oxartnum');
                    var $self = $(this);

                    if (window.Wishlist.Product.isOnList(oxartnum)) {
                        $self.closest('.fancybox-skin').find('button.add-to-wishlist').hide();
                        $self.closest('.fancybox-skin').find('button.delete-from-wishlist').show();
                    } else {
                        $self.closest('.fancybox-skin').find('button.add-to-wishlist').show();
                        $self.closest('.fancybox-skin').find('button.delete-from-wishlist').hide();
                    }

                    $self.closest('.fancybox-skin').find('button.star-info-box').attr('data-oxartnum', oxartnum);
                })
                .on('beforeChange', function(event, slick, currentSlide, nextSlide){
                    var $self = $(this);
                    var oxartnum = $(slick.$slides.get(nextSlide)).find('img').data('oxartnum');

                    if (window.Wishlist.Product.isOnList(oxartnum)) {
                        $self.closest('.fancybox-skin').find('button.add-to-wishlist').hide();
                        $self.closest('.fancybox-skin').find('button.delete-from-wishlist').show();
                    } else {
                        $self.closest('.fancybox-skin').find('button.add-to-wishlist').show();
                        $self.closest('.fancybox-skin').find('button.delete-from-wishlist').hide();
                    }

                    $self.closest('.fancybox-skin').find('button.star-info-box').attr('data-oxartnum', oxartnum);
                });

            $('.modell-slider-master').slick({
                lazyLoad: 'ondemand',
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: true,
                speed: 500,
                arrows: false,
                draggable: false,
                touchMove: false
            });

            $('.modell-slider-thumb').slick({
                slidesToShow: 12,
                slidesToScroll: 1,
                infinite: false,
                asNavFor: '.modell-slider--navFor',
                arrows: true,
                focusOnSelect: true,
                prevArrow: '<span id="colorviewerprev"><i class="fa fa-chevron-left"></i></span>',
                nextArrow: '<span id="colorviewernext"><i class="fa fa-chevron-right"></i></span>',
                responsive: [
                    {
                        breakpoint: 1000,
                        settings: {
                            slidesToShow: 10
                        }
                    },
                    {
                        breakpoint: 690,
                        settings: {
                            slidesToShow: 8
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 7
                        }
                    },
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: 4
                        }
                    },
                    {
                        breakpoint: 360,
                        settings: {
                            slidesToShow: 3
                        }
                    }
                ]
            });
            $('.modell-slider-artnum').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                draggable: false,
                touchMove: false,
                fade: true
            });
            $('.modell-slider-wishlist').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                draggable: false,
                touchMove: false,
                fade: true
            });

            $('.fancy-prev').click(function() {
                $.fancybox.prev();
            });
            $('.fancy-next').click(function() {
                $.fancybox.next();
            });
        },
        afterClose: function() {
            $(".fancybox-arrow").hide();
        },
        afterLoad: function() {
            console.log('afterLoad');

            // bzgl. IE 10 BUG:
            // https://stackoverflow.com/questions/7127896/problem-using-elem-dataset-with-ie-and-jsfiddle
            // https://stackoverflow.com/questions/23596751/dataset-vs-data-difference

            var masterBase = this.element.context.dataset.fancyboxMasterbasepath;
            //console.log(this.element.context.dataset.fancyboxPics);
            var res = this.element.context.dataset.fancyboxPics.split(";");
            if(res.length > 1) {
                var artNums = this.element.context.dataset.fancyboxArtnum.split(";");
                var thumbBase = this.element.context.dataset.fancyboxThumbbasepath;
                var connsale = this.element.context.dataset.fancyboxConnsale;
                var masterImgs = '';
                var thumbImgs = '';
                var sArtNums = '';
                var sWishlistButtons = '';
                $.each(res, function(index, value) {
                    if ($('.buegel-dev').length > 0) {
                        masterImgs += '<div><img class="img-responsive" data-oxartnum="' + artNums[index] + '" data-lazy="'+masterBase+value+'">';
                        if (connsale === '1') {
                            masterImgs += '<span class="model-item--sale-badge"><img src="/img/sale.png" alt="sale"></span>';
                        }
                        masterImgs += '</div>';
                    } else {
                        masterImgs += '<div><img class="img-responsive" data-oxartnum="' + artNums[index] + '" data-lazy="'+masterBase+value+'"></div>';
                    }

                    thumbImgs += '<div class="modell-slider-img-outer"><div class="modell-slider-border"><img src="'+thumbBase+value+'"></div></div>';
                    sArtNums += '<strong>'+artNums[index]+'</strong>';
                    sWishlistButtons += '<span><button class="star-info-box pull-right delete-from-wishlist" data-oxartnum="' + artNums[index] + '"><span><i class="icon-star"></i></span></button></span>';
                });

                this.content = '<div class="modell-slider-master modell-slider--navFor">'+masterImgs+'</div><div class="modell-slider-thumb">'+thumbImgs+'</div>';

                //this.title = '<span class="fancybox-title">'+this.title+'</span><div class="row"><div class="col-sm-3"><div class="modell-slider-artnum modell-slider--navFor">'+sArtNums+'</div></div><div class="col-sm-9">'+this.element.context.dataset.fancyboxContent+'</div></div>';

                this.title = '<div class="row"><div class="col-xs-12"><h3 class="border-bottom fancybox-subinfos"><span>'+this.title+'</span>';

                if (this.element.context.dataset.fancyboxPricecat) {
                    this.title += '<span class="pricecat-right;">Preiskategorie '+this.element.context.dataset.fancyboxPricecat+'</span>';
                }

                this.title += '</h3></div></div>';
                this.title += '<div class="row"><div class="col-xs-12 col-sm-3 col-lg-2">';
                this.title += '<p class="info-box--productnr modell-slider-artnum modell-slider--navFor">'+sArtNums+'</p>';

                // if ($('.buegel-dev').length > 0) {
                //     $('<span class="modell-slider-wishlist">' + sWishlistButtons + '</span>').appendTo('.fancybox-skin');
                // }

                this.title += '</div><div class="col-xs-12 col-sm-9 col-lg-10">';

                if (this.element.context.dataset.fancyboxContent) {
                    this.title += '<p>'+this.element.context.dataset.fancyboxContent+'</p>';
                }

                this.title += '</div></div>';


            } else {

                this.content = '<img class="img-responsive" src="'+this.element.context.href+'">';

                this.title = '<div class="row"><div class="col-xs-12"><h3 class="border-bottom fancybox-subinfos"><span>'+this.title+'</span>';
                if (this.element.context.dataset.fancyboxPricecat) {
                    this.title += '<span class="pricecat-right">Preiskategorie '+this.element.context.dataset.fancyboxPricecat+'</span>';
                }

                this.title += '</h3></div></div>';
                this.title += '<div class="row"><div class="col-xs-12 col-sm-3 col-lg-2">';
                this.title += '<p class="info-box--productnr"><strong>'+this.element.context.dataset.fancyboxArtnum+'</strong></p>';

                this.title += '</div><div class="col-xs-12 col-sm-9 col-lg-10">';

                if (this.element.context.dataset.fancyboxContent) {
                    this.title += '<p>'+this.element.context.dataset.fancyboxContent+'</p>';
                }

                this.title += '</div></div>';
            }
        }
    });
});
